$(document).on('turbolinks:load', function() {  
    $('.l-header__button').on('click',function(){
      $('.l-header__button__border1').toggleClass('border1');
      $('.l-header__button__border2').toggleClass('border2');
      $('.l-header__button__border3').toggleClass('border3');
      $('.l-header__right2').toggleClass('right2');
      $('.l-header__right2__link2').toggleClass('right2__link');
      $('.l-header__right2__link3').toggleClass('right2__link');
      $('.l-header__right2__link4').toggleClass('right2__link');
      $('.l-header__right2__link5').toggleClass('right2__link');
      $('.l-header__right2__link6').toggleClass('right2__link');
      $('.l-header__right2__link7').toggleClass('right2__link');
      $('.l-header__right2__link8').toggleClass('right2__link');
      $('.l-header__right2__link9').toggleClass('right2__link');
      $('.l-header__right2__link10').toggleClass('right2__link');
      $('.l-header__right2__link11').toggleClass('right2__link');
      $('body').toggleClass('scroll');
    });

    $('.c-profiles__carrer__center__wrapper').on('click',function(){
      $('.c-careers__new').css(
        {
        'visibility':'visible',
        'opacity':'1'
      });
      $('.overlay').css(
        {
          'visibility':'visible',
          'opacity':'0.6'
        }
      )
    });
    $('.c-careers__new__cross,.overlay').on('click',function(){
      $('.c-careers__new,.overlay').css(
        {
        'visibility':'hidden',
        'opacity':'0'
      });
    });
    
});


$(document).on('turbolinks:before-visit', function() {
  $('body').css('opacity', 0);
});

$(document).on('turbolinks:load', function() {
  $('body').animate({ opacity: 1 }, 1000);
});