$(document).on('turbolinks:load', function() {  
    $('.c-news__content__submit__wrapper').on('click',function(){
        let title = $('.c-news__content__title__edit').val();
        let detail = $('.c-news__content__detail__edit').val();
        if(title == ''){
            $('.c-news__content__title__error').html("タイトルを入力してください。")
        }else {
            $('.c-news__content__title__error').empty();
        }
        if(detail == ''){
            $('.c-news__content__detail__error').html("詳細を入力してください。")
        }else {
            $('.c-news__content__detail__error').empty();
        }
    });
    
    $('.c-news__content__title__edit,.c-news__content__detail__edit').on('input',function(){
        let title = $('.c-news__content__title__edit').val();
        let detail = $('.c-news__content__detail__edit').val();

        if(title == '' || detail == ''){
            $('.c-news__content__submit__wrapper').html("<span class='c-news__content__submit__s'>作成</span>")
        }else {
            $('.c-news__content__submit__wrapper').html("<input type='submit' value='作成' class='c-news__content__submit' />")
        }
    }); 


    $('#image1,#image2,#image3,#image4,#image5,.c-profiles__detail__area').on('change',function(){
        $('.c-profiles__submit').html("<input type='submit' value='保存' class='c-news__content__submit' />")
    }); 
});

