$(document).on('turbolinks:load', function() {  
    $(function(){
        setTimeout(function(){
          $(".c-contacts__flash").animate({
            'padding' : '0px',
            'height' : '0px',
            'opacity' : '0',
            'margin-bottom' : '0',
            'border' : '0px solid #46b450'
          },500,'swing');
        },3000);
      });
});