$(document).on('turbolinks:load', function() {  
    $('.c-contacts__content__submit').on('click',function(){
        let name = $('.c-contacts__content__form__right__name').val();
        let email = $('.c-contacts__content__form__right__email').val();
        let detail = $('.c-contacts__content__form__right__detail').val();
        let message = $('.c-contacts__content__form__right__message').val();
        if(name == ''){
            $('.c-contacts__content__form__errorRight__name').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__name').empty();
        }
        if(email == ''){
            $('.c-contacts__content__form__errorRight__email').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__email').empty();
        }
        if(detail == ''){
            $('.c-contacts__content__form__errorRight__detail').html("必須項目を選択してください。")
        }else {
            $('.c-contacts__content__form__errorRight__detail').empty();
        }
        if(message == ''){
            $('.c-contacts__content__form__errorRight__message').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__message').empty();
        }
    });
    $('.c-contacts__content__form__right__name,.c-contacts__content__form__right__email,.c-contacts__content__form__right__detail,.c-contacts__content__form__right__message').on('input',function(){
        let name = $('.c-contacts__content__form__right__name').val();
        let email = $('.c-contacts__content__form__right__email').val();
        let detail = $('.c-contacts__content__form__right__detail').val();
        let message = $('.c-contacts__content__form__right__message').val();

        if(name == '' || email == '' || detail == '' || message == ''){
            $('.c-contacts__content__submit').html("<span class='c-contacts__content__submit__button required'>この内容で送信する</span>")
        }else {
            $('.c-contacts__content__submit').html("<input type='submit' value='この内容で送信する' class='c-contacts__content__submit__button u-not-required' />")
        }
    }); 

    $('.c-contacts__content__submit2').on('click',function(){
        let name2 = $('.c-contacts__content__form__right__name2').val();
        let email2 = $('.c-contacts__content__form__right__email2').val();
        let detail2 = $('.c-contacts__content__form__right__detail2').val();
        let message2 = $('.c-contacts__content__form__right__message2').val();
        if(name2 == ''){
            $('.c-contacts__content__form__errorRight__name2').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__name2').empty();
        }
        if(email2 == ''){
            $('.c-contacts__content__form__errorRight__email2').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__email2').empty();
        }
        if(detail2 == ''){
            $('.c-contacts__content__form__errorRight__detail2').html("必須項目を選択してください。")
        }else {
            $('.c-contacts__content__form__errorRight__detail2').empty();
        }
        if(message2 == ''){
            $('.c-contacts__content__form__errorRight__message2').html("必須項目に入力してください。")
        }else {
            $('.c-contacts__content__form__errorRight__message2').empty();
        }
    });
    $('.c-contacts__content__form__right__name2,.c-contacts__content__form__right__email2,.c-contacts__content__form__right__detail2,.c-contacts__content__form__right__message2').on('input',function(){
        let name2 = $('.c-contacts__content__form__right__name2').val();
        let email2 = $('.c-contacts__content__form__right__email2').val();
        let detail2 = $('.c-contacts__content__form__right__detail2').val();
        let message2 = $('.c-contacts__content__form__right__message2').val();

        if(name2 == '' || email2 == '' || detail2 == '' || message2 == ''){
            $('.c-contacts__content__submit2').html("<span class='c-contacts__content__submit__button required'>この内容で送信する</span>")
        }else {
            $('.c-contacts__content__submit2').html("<input type='submit' value='この内容で送信する' class='c-contacts__content__submit__button u-not-required' />")
        }
    }); 
});

