$(document).on('turbolinks:load', function() {  
    $(function(){
        $('#image').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image c-home__profiles__content__right__image3'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-news__content__image__preview2').html(text);
        });
    });
    $(function(){
        $('#image1').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image1 c-news__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image1").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-news__content__image1__preview').html(text);
        });
    });
    $(function(){
        $('#image2').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image2 c-news__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image2").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-news__content__image2__preview').html(text);
        });
    });

    $(function(){
        $('#image1').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image1 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image1").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__image1__preview').html(text);
        });
    });
    $(function(){
        $('#image2').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image2 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image2").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__image2__preview').html(text);
        });
    });
    $(function(){
        $('#image3').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image3 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image3").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__image3__preview').html(text);
        });
    });
    $(function(){
        $('#image4').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image4 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image4").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__image4__preview').html(text);
        });
    });
    $(function(){
        $('#image5').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='image5 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".image5").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__image5__preview').html(text);
        });
    });

    $(function(){
        $('#profile_image1').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='profile_image1 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".profile_image1").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__profile__image1__preview').html(text);
        });
    });
    $(function(){
        $('#profile_image2').on('change', function (e) {
            var text = `
                <img src="", alt="preview" class='profile_image2 c-profiles__content__image__preview__size'>
            `
            var reader = new FileReader();
            reader.onload = function (e) {
                $(".profile_image2").attr('src', e.target.result);
            }
            reader.readAsDataURL(e.target.files[0]);
            $('.c-profiles__content__profile__image2__preview').html(text);
        });
    });
});