$(document).on('turbolinks:load', function() {  
    $('.c-news__content__submit__wrapper').on('click',function(){
        let year = $('.c-news__content__day__right__year').val();
        let month = $('.c-news__content__day__right__month').val();
        let day = $('.c-news__content__day__right__day').val();
        let time1 = $('.c-news__content__day__right__time1').val();
        let place = $('.c-news__content__day__right__place').val();
        let price = $('.c-news__content__day__right__price').val();
        if(year == ''){
            $('.c-news__content__day__right__year__error').html("年を入力してください。")
        }else {
            $('.c-news__content__day__right__year__error').empty();
        }
        if(month == ''){
            $('.c-news__content__day__right__month__error').html("月を入力してください。")
        }else {
            $('.c-news__content__day__right__month__error').empty();
        }
        if(day == ''){
            $('.c-news__content__day__right__day__error').html("日を入力してください。")
        }else {
            $('.c-news__content__day__right__day__error').empty();
        }
        if(time1 == ''){
            $('.c-news__content__day__right__time1__error').html("開催時間を入力してください。")
        }else {
            $('.c-news__content__day__right__time1__error').empty();
        }
        if(place == ''){
            $('.c-news__content__day__right__place__error').html("場所を入力してください。")
        }else {
            $('.c-news__content__day__right__place__error').empty();
        }
        if(price == ''){
            $('.c-news__content__day__right__price__error').html("料金を入力してください。")
        }else {
            $('.c-news__content__day__right__price__error').empty();
        }
    });
    
    $('.c-news__content__title__edit,.c-news__content__detail__edit').on('input',function(){
        let title = $('.c-news__content__title__edit').val();
        let year = $('.c-news__content__day__right__year').val();
        let month = $('.c-news__content__day__right__month').val();
        let day = $('.c-news__content__day__right__day').val();
        let time1 = $('.c-news__content__day__right__time1').val();
        let place = $('.c-news__content__day__right__place').val();
        let price = $('.c-news__content__day__right__price').val();
        let detail = $('.c-news__content__detail__edit').val();

        if(title == '' || year == '' || month == '' || day == '' || time1 == '' || place == ''  || price == ''  || detail == ''){
            $('.c-news__content__submit__wrapper').html("<span class='c-news__content__submit__s'>作成</span>")
        }else {
            $('.c-news__content__submit__wrapper').html("<input type='submit' value='作成' class='c-news__content__submit' />")
        }
    }); 
});

